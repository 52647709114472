import { employeeService, employeeAuditTrailService } from '../../../services'
import { FETCH_EMPLOYEES, CREATE_EMPLOYEES, FETCH_PENDING_EMPLOYEES, FETCH_EMPLOYEES_SEARCH, APPROVE_EMPLOYEE, REJECT_EMPLOYEE, RETURN_EMPLOYEE } from '../../action-type'
import { SET_EMPLOYEES, SET_PENDING_EMPLOYEES, SET_EMPLOYEE_APPROVAL, REMOVE_EMPLOYEE } from '../../mutation-type'

export default {
  state: {
    employeesSearch: [
      {
        firstName: 'Kojo',       
        email: 'OKAB@chevron.com'
      },
      {
        firstName: 'Gabriel',        
        email: 'GAEX@chevron.com'
      },
      {
        firstName: 'Xavier',       
        email: 'XABA@chevron.com'
      },
      {
        firstName: 'Kunle',        
        email: 'Bjth@chevron.com'
      },
      {
        firstName: 'Peter',        
        email: 'PDUJ@chevron.com'
      }
    ],
    pendingEmployees: [],
    employees: []
  },
  mutations: {
    [SET_PENDING_EMPLOYEES] (state, payload) {
      state.pendingEmployees = [...payload]
    },
    [SET_EMPLOYEES] (state, payload) {
      state.employees = [...payload]
    },
    [REMOVE_EMPLOYEE] (state, id) {
      state.pendingEmployees = state.pendingEmployees.filter(emp => emp.id !== id)
    },
    [SET_EMPLOYEE_APPROVAL] (state, payload) {
      let index = null
      for (let i = 0; i < state.pendingEmployees.length; i++) {
        if (state.pendingEmployees[i].id === payload.id) {
          index = i
          break
        }
      }
      state.pendingEmployees.splice(index, 1)
    }
  },
  actions: {
    async [FETCH_EMPLOYEES_SEARCH] ({ commit }, searchString) {
      return new Promise((resolve, reject) => {
        employeeService
          .searchEmployees(searchString)
          .then(result => {
            console.log(result)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async [FETCH_EMPLOYEES] ({ commit }) {
      return new Promise((resolve, reject) => {
        employeeService.getAllEmployees().then(result => {
          // console.log(result)
          commit(SET_EMPLOYEES, result.data.items)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [CREATE_EMPLOYEES] ({ commit }, employee) {
      return new Promise((resolve, reject) => {
        employeeAuditTrailService.createEmployee(employee).then(result => {
          console.log(result)
          resolve()
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    async [FETCH_PENDING_EMPLOYEES] ({ commit }) {
      return new Promise((resolve, reject) => {
        employeeAuditTrailService.getAllPendingEmployees().then(result => {
          console.log(result.data.items)
          commit(SET_PENDING_EMPLOYEES, result.data.items)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async [APPROVE_EMPLOYEE] ({ commit }, employee) {
      return new Promise((resolve, reject) => {
        employeeAuditTrailService.approveEmployee(employee).then(result => {
          commit(SET_EMPLOYEE_APPROVAL, employee)
          resolve()
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    async [REJECT_EMPLOYEE] ({ commit }, data) {
      return new Promise((resolve, reject) => {
        employeeAuditTrailService.rejectEmployee(data).then(result => {
          commit(REMOVE_EMPLOYEE, data.id)
          resolve(result)
        }).catch(error => [
          reject(error)
        ])
      })
    },
    async [RETURN_EMPLOYEE] ({ commit }, data) {
      return new Promise((resolve, reject) => {
        employeeAuditTrailService.returnEmployee(data).then(result => {
          commit(REMOVE_EMPLOYEE, data.id)
          resolve(result)
        }).catch(error => [
          reject(error)
        ])
      })
    }

  },
  getters: {
    employee (state) {    
    },
    pendingEmployees (state) {
      return state.pendingEmployees
    },
    employees (state) {
      return state.employees
    }
  }
}
