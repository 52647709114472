<template>
<div>
    <AppHeader />
    <!-- <AppNavigation /> -->
    <SpinnerDialog v-bind="spinnerDialogData"></SpinnerDialog>
    <ConfirmationDialog v-bind="confirmationDialogData"></ConfirmationDialog>
    <InformationDialog v-bind="informationDialogData"></InformationDialog>
    <main class="container">
        <!-- COLUMN 1 -->
      <section class="column" id="column1">
        <v-app>
            <v-alert dark dismissible v-model="alert" border="left" class="alert" :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
                {{alertMessage}}
            </v-alert>
            <div class="row mt-5">
              <div class="col-md-6 p-4">
                <h3>Single Sign On (Chevron Internal User Only)</h3>
                <v-row>
                  <v-col cols="6" sm="12" class="d-flex justify-center">
                    <Button :label="'Click here to Continue'" @onClick="onSingleLogin" :btnType="'Submit'" class="mr-4" />
                  </v-col>
                </v-row>
              </div>
              <div class="col-md-6 p-1">
                  <div class="login-container p-2">
                    <h3>3rd Party Login</h3>
                    <v-form ref="loginform">
                      <v-row no-gutters dense>
                        <v-col v-show="loginError" cols="9" sm="12" class="mx-auto">
                          <p class="text-danger">
                            Invalid username or password
                          </p>
                        </v-col>
                        <v-col cols="9" sm="12" class="mx-auto">
                          <TextField :label="'Username'" v-model="username" />
                        </v-col>
                        <v-col cols="9" sm="12" class="mx-auto">
                          <v-text-field
                            dense
                            outlined
                            v-model="password"
                            :append-icon="showPasswordHint ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPasswordHint ? 'text' : 'password'"
                            label="Password"
                            @click:append="showPasswordHint = !showPasswordHint"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-row>
                            <v-col cols="4" class="d-flex justify-start">
                              <!-- <router-link to="/forgot-password">Forgot Password</router-link> -->
                            </v-col>
                            <v-col cols="8" class="d-flex justify-end">
                              <Button :label="'Login'" @onClick="onLogin" :btnType="'Submit'" :disabled="sending" class="mr-4" />
                              <Button :disabled="sending" :label="'Reset'" @onClick="onReset" :btnType="'Cancel'" />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-form>
                  </div>
              </div>
            </div>
        </v-app>
      </section>
    </main>
    <AppFooter />
    <div id="overlay">
        <div id="overlay-outerwrap">
            <a id="overlay-close"></a>
            <div id="overlay-wrap">
                <div id="overlay-wrapinside"></div>
            </div>
        </div>
    </div>
    <ScrollToTopButton></ScrollToTopButton>
</div>
</template>

<script>
import LayoutMixin from '@/mixins/AppLayoutMixin.vue'
import Button from '@/components/ui/form/Button.vue'
import TextField from '@/components/ui/form/TextField.vue'
import Roles from '@/utils/roles'
//import AdalAuthentication from '@/adal-auth'
  import MsalAuthentication from '@/msal-auth'

  import CryptoJS from 'crypto-js'


// import { Keys } from '@/utils/constants'

import { accountService } from '@/services'

export default {
  components: {
    Button,
    TextField
  },
  name: 'loginLayout',
  mixins: [LayoutMixin],
  data: () => ({
    dialog: false,
    layoutClass: 'layout-12',
    username: '',
    password: '',
    showPassword: false,
    alertMessage: '',
    alert: false,
    alertType: '',
    sending: false,
    loginError: false,
    showPasswordHint: false
  }),
  methods: {
    async onLogin () {
      this.loginError = false
      this.sending = true
      const payload = {
        username: this.username,
        password: this.password
      }

      try {
        accountService.customLogin(payload)
          .then(response => {
            if (response.status === 200) {
              const userInfo = response.data              
              const user = userInfo.contractEmployerUserDTO.user                          
              localStorage.setItem('user-token', response.data.authenticationToken)
              localStorage.setItem('custom-user', CryptoJS.AES.encrypt(userInfo.contractEmployerUserDTO.username, 'secret key'))
              if (user.isNewUser === true) {
                localStorage.setItem('newuser', CryptoJS.AES.encrypt(userInfo.contractEmployerUserDTO.username, 'secret key'))
                this.$router.go('/contract-employer/complete')
              } else {
                this.$router.go('/')
              }
            } else {
              this.loginError = true
              this.showAlertMessage('Invalid username or password', 'error')
            }
          }).catch(() => {
            this.loginError = true
          }).finally(() => {
            this.sending = false
          })
      } catch (error) {
        this.showAlertMessage('Unable to login, please try again later', 'error')
        this.sending = false
      }
    },
    onSingleLogin () {
      // MsalAuthentication.signOut()
       MsalAuthentication.login()
      //AdalAuthentication.initialize().then(() => AdalAuthentication.signIn())
    },
    onCheckUserType () {
      this.sending = true
      accountService.getUserRole(this.username)
        .then(response => {
          if (response.status === 200) {
            const data = response.data
            if (data.roleName === Roles.LCE && !data.name.includes('@chevron')) {
              this.showPassword = true
              // if (data.isNewUser === true) {
              //   // localStorage.setItem('newuser', data.name)
              //   // this.$router.go('/contract-employer/complete/')
              // } else {
              //   this.showPassword = true
              // }
            } else {
              MsalAuthentication.login()
            }
          } else {
            this.showAlertMessage('Invalid username', 'error')
          }

          this.sending = false
        })
    },
    onReset () {
      this.username = ''
      this.password = ''
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    onConfirm () {
      this.dialog = false
      localStorage.setItem('acceptedTC', true)
    },
    onCancel () {
      this.dialog = false
    }
  },
  mounted () {
    this.dialog = false
  },
  beforeMount () {
    // AdalAuthentication.initialize().then(() => AdalAuthentication.signIn())
    // MsalAuthentication.initialize()
    // const isLoggedIn = MsalAuthentication.isAuthenticated()
    // console.log('is login? ', isLoggedIn)
    // MsalAuthentication.acquireToken().then(res => {
    //   console.log(res)
    // }).catch(error => {
    //   if (isLoggedIn) {
    //     MsalAuthentication.login()
    //   }
    //   console.log(error)
    // })
  }
}
</script>

<style scoped>
  .tc-dialog, .v-dialog {
    background: #fff !important;
  }
  .tc-header {
    display: block;
    font-size: 1rem;
    text-align: center !important;
    font-family: "GothamBold",Arial,sans-serif;
  }
  .confrim {
    color: #fff;
    background-color: #0066b2 !important;
    font-family: "GothamBold",Arial,sans-serif;
  }
  .cancel {
    color: #fff;
    background-color: #dc3545 !important;
    font-family: "GothamBold",Arial,sans-serif;
  }
  .alert {
    position: fixed;
    z-index: 9999;
    top: 5px;
    min-width: 50%;
  }

  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .login-container {
    border: 1px silver solid;
    border-radius: 5px;
  }

  /* main{
  background: url('../../assets/bg.svg');
} */
</style>
